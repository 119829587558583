import api from '../../api/layout'

// initial state
const state = () => ({
  actions_to_do: null,
  available_currencies: null,
  available_languages: null,
  colors: null,
  current_currency: { code: localStorage.getItem('currency') },
  current_language: { code: localStorage.getItem('lang') },
  default_currency: null,
  header_infos: null,
  icons: null,
  main_menu: null,
  operators: null,
  settings: null,
  trans: null,
})

// getters
const getters = {
  actionsToDoCount: (state) => {
    let count = 0;
    if (state.actions_to_do && state.actions_to_do.length) {
      state.actions_to_do.forEach(function (action) {
        count += action.count
      })
    }
    return count
  },
}

// actions
const actions = {
  getActionsToDo({ commit }) {
    api.getActionsToDo(actions_to_do => {
      commit('setActionsToDo', actions_to_do)
    })
  },
  getInitInfos({ commit }) {
    return api.getInitInfos(infos => {
      commit('setCurrentCurrency', infos.currency.current)
      commit('setDefaultCurrency', infos.currency.default)
      commit('setAvailableCurrencies', infos.currency.available)
      commit('setCurrentLanguage', infos.language.current)
      commit('setAvailableLanguages', infos.language.available)
      commit('setColors', infos.colors)
      commit('setIcons', infos.icons)
      commit('setOperators', infos.operators)
      commit('setSettings', infos.settings)
      commit('setTrans', infos.trans)
    })
  },
  setCurrentCurrency({ commit }, curr) {
    api.setCurrentCurrency(curr).then(currency => {
      commit('setCurrentCurrency', currency.current)
      window.location.reload()
    })
  },
  setCurrentLanguage({ commit }, lang) {
    api.setCurrentLanguage(lang).then(language => {
      commit('setCurrentLanguage', language.current)
      window.location.reload()
    })
  },
  getHeaderInfos({ commit }) {
    api.getHeaderInfos(header_infos => {
      commit('setHeaderInfos', header_infos)
    })
  },
  getMainMenu({ commit }) {
    api.getMainMenu(main_menu => {
      commit('setMainMenu', main_menu)
      document.body.classList.add("sidebar-lg-show");
    })
  },
}
actions.refresh = (commit) => {
  actions.getActionsToDo(commit);
  actions.getInitInfos(commit);
  actions.getHeaderInfos(commit);
  actions.getMainMenu(commit);
};

// mutations
const mutations = {
  setActionsToDo(state, actions_to_do) {
    state.actions_to_do = actions_to_do
  },
  setAvailableCurrencies(state, available_currencies) {
    state.available_currencies = available_currencies
  },
  setAvailableLanguages(state, available_languages) {
    state.available_languages = available_languages
  },
  setColors(state, colors) {
    state.colors = colors
  },
  setCurrentCurrency(state, current_currency) {
    localStorage.setItem('currency', current_currency.code);
    state.current_currency = current_currency
  },
  setCurrentLanguage(state, current_language) {
    localStorage.setItem('lang', current_language.code);
    state.current_language = current_language
  },
  setDefaultCurrency(state, default_currency) {
    state.default_currency = default_currency
  },
  setHeaderInfos(state, header_infos) {
    state.header_infos = header_infos
  },
  setIcons(state, icons) {
    state.icons = icons.list
    state.available_icons = icons.available
  },
  setMainMenu(state, main_menu) {
    state.main_menu = main_menu
  },
  setOperators(state, operators) {
    state.operators = operators
  },
  setSettings(state, settings) {
    state.settings = settings
  },
  setTrans(state, trans) {
    state.trans = trans
  },
}
mutations.reset = (state) => {
  mutations.setActionsToDo(state, null);
  mutations.setMainMenu(state, null);
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}