<template>
  <b-button
    :to="{ name: 'messages' }"
    variant="none"
    size="lg"
    class="p-1"
    :title="$t('message.model.plural')"
    v-b-tooltip
  >
    <font-awesome-layers class="fa-fw">
      <font-awesome-icon
        v-if="icons"
        :icon="['fal', icons.message]"
      />
      <font-awesome-layers-text
        v-if="total_count"
        counter
        :value="total_count"
        position="top-right"
        class="fa-2x"
      />
    </font-awesome-layers>
  </b-button>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderMessages",
  computed: {
    ...mapState({
      header_infos: (state) => state.layout.header_infos,
      icons: (state) => state.layout.icons,
    }),
    total_count() {
      return this.header_infos ? this.header_infos.unread_messages_count : 0;
    },
  },
};
</script>
