import axios from "../../services/api"

let default_root = '/bwapi/';
let root = default_root + 'cart';
// let config_name = 'cart';

export default {
    get(type, callback) {
        return axios.get(root + '/' + type)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                return Promise.reject(response.response)
            });
    },
    add(type, item, callback) {
        return axios.post(root + '/' + type + '/add', { item: item })
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                return Promise.reject(response.response)
            });
    },
    changeQuantity(type, item, quantity, callback) {
        return axios.post(root + '/' + type + '/change-quantity', { item: item, quantity: quantity })
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                return Promise.reject(response.response)
            });
    },
    remove(type, item, callback) {
        return axios.post(root + '/' + type + '/remove', { item: item })
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                return Promise.reject(response.response)
            });
    },
    empty(type, callback) {
        return axios.post(root + '/' + type + '/empty')
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                return Promise.reject(response.response)
            });
    },
    getDataForSupplier(type, supplier_id, callback) {
        return axios.get(root + '/' + type + '/data-for-supplier/' + supplier_id)
            .then((response) => {
                callback(response.data);
                return response.data;
            })
            .catch((response) => {
                return Promise.reject(response.response)
            });
    },
    convert(type, form, callback) {
        return axios.post(root + '/' + type + '/convert', form)
            .then((response) => {
                callback(response.data);
                return response.data;
            })
            .catch((response) => {
                return Promise.reject(response.response)
            });
    },
}