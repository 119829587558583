<template>
  <b-modal
    :id="id"
    :ref="id"
    :header-bg-variant="message && message.variant"
    :hide-footer="!dismissible || !(message && message.dismissible)"
    size="xl"
  >
    <template
      v-if="message"
      #modal-title
    >
      <div :style="colors ? colors.classes : null">
        <font-awesome-icon
          v-if="message_loading || message.icon"
          :icon="['fal', message_loading ? icons.info.loading : message.icon]"
          :spin="message_loading"
        />
        {{ message.label }}
      </div>
    </template>

    <div
      v-if="message"
      v-html="message.content"
    />

    <template #modal-footer>
      <div :style="colors ? colors.classes : null">

        <a
          @click="dismiss(message)"
          class="cursor-pointer"
        >
          <font-awesome-icon :icon="['fal', icons.action.close]" />
          {{ $t('global.action.never_see_again') }}
        </a>
      </div>
    </template>
  </b-modal>
</template>

<script>
import api from "../../api/message";
import { mapState } from "vuex";

export default {
  name: "MessagePreviewModal",
  props: {
    id: { required: true },
    message: { default: null },
    dismissible: { type: Boolean, default: true },
  },
  data() {
    return {
      message_loading: false,
      list_key: "message",
    };
  },
  computed: {
    ...mapState({
      colors: (state) => state.layout.colors,
      icons: (state) => state.layout.icons,
    }),
  },
  methods: {
    dismiss() {
      if (this.dismissible && this.message.dismissible) {
        api.dismiss(
          this.message.id,
          () => {
            this.$store.dispatch("layout/getHeaderInfos");
            this.$root.$emit("refresh-list-" + this.list_key);
          },
          (failure) => {
            this.toast_error(null, null, failure.status);
          }
        );
        this.$bvModal.hide(this.id);
      }
    },
  },
};
</script>
