<template>
  <span
    v-b-tooltip.hover
    :title="accept_empty ? $t('global.info.field_auto_filled_if_empty') : $t('global.info.field_auto_filled')"
    class="mx-1"
  >
    <font-awesome-icon
      :icon="['fal', icons.info.autofilled]"
      size="sm"
      class="text-black-50"
    />
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IconAutofilled",
  props: {
    accept_empty: { type: Boolean, default: true },
  },
  computed: {
    ...mapState({
      icons: (state) => state.layout.icons,
    }),
  },
};
</script>
