<template>
  <h1 class="h4 d-inline">
    <font-awesome-icon
      :icon="['fad', icon ? icon : (icons ? icons.info.loading : 'circle-notch')]"
      :spin="icon === null"
    />
    {{ title }}

    <font-awesome-icon
      v-if="title_alert"
      :icon="['fas', title_alert.icon ? title_alert.icon : icons.info.info]"
      v-b-tooltip.html="title_alert.info"
      :class="'text-'+(title_alert.variant ? title_alert.variant : 'info')"
    />

    <small v-if="title_complement">{{ title_complement }}</small>

    <template v-if="badges">
      <b-badge
        v-for="(badge, key) in badges"
        :key="'badge_' + key"
        :variant="badge.color"
        class="mr-1"
        :title="badge.complement ? badge.complement.title : null"
        v-b-tooltip.hover
      >
        <font-awesome-icon
          v-if="badge.complement && badge.complement.icon"
          :icon="['fal', badge.complement.icon]"
        />
        {{ badge.complement && badge.complement.overriden_label ? badge.complement.overriden_label : badge.label }}
      </b-badge>
    </template>
  </h1>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IconAndTitle",
  props: ["badges"],
  computed: {
    ...mapState({
      title: (state) =>
        typeof state.title === "object" ? state.title.main : state.title,
      title_complement: (state) =>
        typeof state.title === "object" ? state.title.complement : null,
      title_alert: (state) =>
        typeof state.title === "object"
          ? typeof state.title.alert === "object"
            ? state.title.alert
            : { info: state.title.alert }
          : null,
      icon: (state) => state.icon,
      icons: (state) => state.layout.icons,
    }),
  },
};
</script>
