import api from '../../api/user/user'
import router from '../../router'
import store from '../../store'

// initial state
const state = () => ({
  current_user: null,
  login_infos: null,
  price_display_inctax: null,
  user: null,
})

// getters
const getters = {
}

// actions
const actions = {
  getCurrentUser({ commit }) {
    api.getCurrentUser(user => {
      commit('setCurrentUser', user)
      commit('setPriceDisplayInctax', user.price_display_inctax)
    })
  },
  getLoginInfos({ commit }) {
    api.getLoginInfos(infos => {
      commit('setLoginInfos', infos)
      if (infos.is_logged) {
        router.push({ name: "dashboard" });
      }
    })
  },
  getUser({ commit }, user) {
    return api.getItem(user.id, infos => {
      commit('setTitle', infos.title, { root: true })
      commit('setIcon', infos.icon, { root: true })
      commit('setBreadcrumbs', infos.breadcrumbs, { root: true })
      commit('setUser', infos.user)
    })
  },
  //
  async login() {
    localStorage.setItem("auth", "true");
    store.dispatch("user/getCurrentUser");
    store.dispatch("layout/refresh");
  },
  logout({ commit }) {
    api.logout(data => {
      localStorage.removeItem("auth");
      commit('reset', null)
      commit('layout/reset', null, { root: true })
      commit('setBreadcrumbs', null, { root: true })
      if (data.redirect) {
        window.location.replace(data.redirect);
      } else {
        router.push({ name: "login" });
        document.body.classList.remove("sidebar-lg-show");
      }
    })
  },
  reinitLayout({ commit }) {
    localStorage.removeItem("auth");
    commit('reset', null)
    commit('layout/reset', null, { root: true })
    commit('setBreadcrumbs', null, { root: true })
    document.body.classList.remove("sidebar-lg-show");
  },
}

// mutations
const mutations = {
  setCurrentUser(state, current_user) {
    state.current_user = current_user
  },
  setLoginInfos(state, login_infos) {
    state.login_infos = login_infos
  },
  setPriceDisplayInctax(state, price_display_inctax) {
    state.price_display_inctax = price_display_inctax
  },
  setUser(state, user) {
    state.user = user
  },
}
mutations.reset = (state) => {
  mutations.setCurrentUser(state, null);
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}