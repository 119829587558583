<template>
  <div class="app-body">
    <MainMenu />

    <main class="main">
      <message-preview-listing
        v-if="header_infos"
        :messages="header_infos.messages"
      />

      <error-page v-if="error_infos" />
      <template v-else>
        <b-breadcrumb
          v-if="breadcrumb_items && breadcrumb_items.length"
          :items="breadcrumb_items"
        />

        <router-view />
      </template>
    </main>

    <aside class="aside-menu p-2">
      <div class="loading-overlay -mini" />
    </aside>
  </div>
</template>

<script>
import ErrorPage from "../../views/errors/ErrorPage.vue";
import MainMenu from "./MainMenu.vue";
import MessagePreviewListing from "../message/PreviewListing.vue";
import { mapState } from "vuex";

export default {
  name: "MainBody",
  components: {
    ErrorPage,
    MessagePreviewListing,
    MainMenu,
  },
  computed: {
    ...mapState({
      pages_infos: (state) => state.pages_infos,
      breadcrumbs: (state) => state.breadcrumbs,
      error_infos: (state) => state.error_infos,
      header_infos: (state) => state.layout.header_infos,
    }),
    breadcrumb_items: function () {
      let bc = null;
      if (this.breadcrumbs) {
        bc = this.breadcrumbs;
      } else {
        // let pathArray = this.$route.path.split("/");
        // pathArray.shift();
        // bc = pathArray.reduce((breadcrumbArray, path, idx) => {
        //   let text = "";
        //   text = path;
        //   if (this.$route.matched[idx]) {
        //     // text = path;
        //     if (this.$route.matched[idx].meta.breadCrumb) {
        //       text = this.$route.matched[idx].meta.breadCrumb;
        //     } else if (this.$route.matched[idx].meta.title) {
        //       text = this.$route.matched[idx].meta.title;
        //     }
        //   }
        //   breadcrumbArray.push({
        //     path: path,
        //     to: breadcrumbArray[idx - 1]
        //       ? "/" + breadcrumbArray[idx - 1].path + "/" + path
        //       : "/" + path,
        //     text: text,
        //   });
        //   return breadcrumbArray;
        // }, []);
        // // bc = [
        // //   {
        // //     path: "/",
        // //     to: "/",
        // //     text: "HOME",
        // //   },
        // // ].concat(bc);
      }
      // console.debug("bc", bc);
      return bc;
    },
  },
};
</script>
