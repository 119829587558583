const defaultOptions = {};

export default {
    install(Vue, options) {
        // merge default options with arg options
        let userOptions = { ...defaultOptions, ...options };
        userOptions; // to avoid "assigned but never used" message

        /**
         * Transforms form data into a simplifyied array
         * @param {*} form 
         * @returns 
         */
        Vue.prototype.$processSearchFormData = function (form) {
            let data = {};
            for (var field in form) {
                if (Array.isArray(form[field])) {
                    if (form[field].length) {
                        data[field] = [];
                        for (var item in form[field]) {
                            if (form[field][item].id !== undefined) {
                                data[field].push(form[field][item].id);
                            } else if (form[field][item].text !== undefined) {
                                data[field].push("⊥" + form[field][item].text); // to say to Laravel that it's a text, not an ID
                            } else {
                                data[field].push(form[field][item]);
                            }
                        }
                    }
                } else {
                    data[field] = form[field];
                }
            }
            return data;
        }

        /**
         * Transforms an object into a query string
         * @param {*} obj 
         * @param {*} prefix 
         * @returns 
         */
        Vue.prototype.$queryStringify = function (obj, prefix) {
            var str = [];
            for (var p in obj) {
                // if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + "[" + p + "]" : p,
                    v = obj[p];
                str.push(typeof v == "object" ? this.$queryStringify(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
                // }
            }
            return str.join("&");
        }
    }
}