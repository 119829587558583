<template>
  <b-dropdown
    size="lg"
    variant="link"
    toggle-class="text-decoration-none nav-link px-1"
    menu-class="dropdown-menu-right dropdown-menu-lg"
    no-caret
  >
    <template
      #button-content
      class="nav-link"
    >
      <font-awesome-layers
        class="fa-fw"
        :title="$t('user.actions_to_do')"
        v-b-tooltip
      >
        <font-awesome-icon
          v-if="icons"
          :icon="['fal', icons.actions_to_do]"
        />
        <font-awesome-layers-text
          v-if="total_count"
          counter
          :value="total_count"
          position="top-right"
          class="fa-2x"
        />
      </font-awesome-layers>
    </template>

    <b-dropdown-header class="text-center">
      <strong>
        {{ $t('user.actions_to_do') }}
      </strong>
    </b-dropdown-header>
    <template v-if="actions_to_do">
      <template v-for="(item, key) in actions_to_do">
        <b-dropdown-header
          v-if="actions_to_do.length > 1"
          :key="'actions_to_do_'+key"
        >
          <strong>{{ item.user.label }}</strong>
          <a
            v-if="item.user.link"
            :href="item.user.link"
            class="ml-1"
            :title="$t('global.action.log_as')"
          >
            <font-awesome-icon
              v-if="icons"
              :icon="['fal', icons.action.login]"
            />
          </a>
          <span class="badge badge-pill badge-light">{{ item.count }}</span>
        </b-dropdown-header>

        <b-dropdown-item
          v-for="(action, key_2) in item.actions"
          :key="'actions_to_do_'+key+'_'+key_2"
          :href="action.link"
          :to="action.to"
          :variant="action.variant ? action.variant : ''"
        >
          {{ action.label }}
          <span
            v-if="action.count"
            class="badge badge-pill badge-danger"
          >{{ action.count }}</span>
          <span
            v-else-if="action.variant == 'danger'"
            class="badge p-0"
            style="font-size: initial"
          >
            <font-awesome-icon
              v-if="icons"
              :icon="['fas', icons.info.warning]"
            />
          </span>
        </b-dropdown-item>
      </template>

      <b-dropdown-header class="text-center">
        <router-link :to="{ name: 'validations' }">
          {{ $t('validation.all') }}
        </router-link>
      </b-dropdown-header>
    </template>
  </b-dropdown>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "ActionsToDo",
  created() {
    if (this.actions_to_do === null) {
      this.$store.dispatch("layout/getActionsToDo");
    }
  },
  computed: {
    ...mapState({
      actions_to_do: (state) => state.layout.actions_to_do,
      icons: (state) => state.layout.icons,
    }),
    ...mapGetters("layout", {
      total_count: "actionsToDoCount",
    }),
  },
};
</script>
