<template>
  <span
    v-b-tooltip.hover
    :title="$t('validation.required.short')"
    class="field-required"
  >*</span>
</template>

<script>
export default {
  name: "IconRequired",
};
</script>
