<template>
  <div
    id="app"
    :class="'app' + classes"
    :style="colors ? colors.classes : null"
  >
    <MainHeader />
    <MainBody />
    <MainFooter />

    <b-modal
      id="html-modal"
      :title-html="modal_title"
      :size="modal_size"
      hide-footer
    >
      <div
        :style="colors ? colors.classes : null"
        v-html="modal_body"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainBody from "./components/layout/MainBody.vue";
import MainFooter from "./components/layout/MainFooter.vue";
import MainHeader from "./components/layout/MainHeader.vue";
import axios from "./services/api";

export default {
  name: "App",
  data() {
    return {
      modal_title: null,
      modal_body: null,
      modal_size: "lg",
    };
  },
  created() {
    if (this.available_languages === null) {
      this.$store.dispatch("layout/getInitInfos");
    }
  },
  mounted() {
    if (!this.$root._events["open-html-modal"]) {
      this.$root.$on("open-html-modal", (link) => {
        axios.get(link).then((response) => {
          this.modal_title = response.data.title;
          this.modal_body = response.data.body;
          this.modal_size = response.data.size ? response.data.size : "lg";
          this.$bvModal.show("html-modal");
        });
      });
    }
    if (!this.$root._events["close-html-modal"]) {
      this.$root.$on("close-html-modal", () => {
        this.$bvModal.hide("html-modal");
      });
    }
  },
  destroyed() {
    this.$root.$off("open-html-modal");
    this.$root.$off("close-html-modal");
  },
  components: {
    MainBody,
    MainFooter,
    MainHeader,
  },
  computed: {
    ...mapState({
      colors: (state) => state.layout.colors,
      classes: (state) =>
        state.pages_infos ? " " + state.pages_infos.classes : "",
    }),
  },
};
</script>

<style>
body {
  --main-color: #20a8d8;
  --main-color-light: #47bbe4;
  --main-color-dark: #1985ac;
  --main-color-R: 32;
  --main-color-G: 168;
  --main-color-B: 216;
}
</style>