<template>
  <div class="container-fluid my-3">
    <b-card
      no-body
      class="text-center"
    >
      <b-card-header>
        <h1 class="h4">
          <font-awesome-icon :icon="['fad', icon]" />
          {{ title }}
        </h1>
      </b-card-header>
      <b-card-body>
        <p>{{ description }}</p>
        <p>
          <a
            v-if="redirect_href"
            :href="redirect_href"
          >
            {{ redirect_text }}
          </a>
          <router-link
            v-else
            :to="{ name: 'dashboard' }"
          >
            {{ this.$t("error.go_back_home") }}
          </router-link>
        </p>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ErrorPage",
  data() {
    return {
      redirect_href: null,
      redirect_text: null,
    };
  },
  created() {
    if (this.error_infos === null) {
      this.$store.commit("setErrorInfos", { status: 404 }, { root: true });
    }
  },
  computed: {
    ...mapState({
      icons: (state) => state.layout.icons,
      error_infos: (state) => state.error_infos,
    }),
    icon() {
      if (this.icons) {
        switch (this.error_infos.status) {
          case 403:
            return this.icons.error[403];
          case 404:
            return this.icons.error[404];
          case 498:
            return this.icons.error[498];
          case 500:
            return this.icons.error[500];
          case 503:
            return this.icons.error[503];
          default:
            return this.icons.error.default;
        }
      }
      return null;
    },
    title() {
      switch (this.error_infos.status) {
        case 403:
          return this.$t("error.403.title");
        case 404:
          return this.$t("error.404.title");
        case 498:
          return this.$t("error.498.title");
        case 500:
          return this.$t("error.500.title");
        case 503:
          return this.$t("error.503.title");
        default:
          return this.$t("error.impossible_action.title", {
            code: this.error_infos.status,
          });
      }
    },
    description() {
      switch (this.error_infos.status) {
        case 401:
          this.$store.dispatch("user/reinitLayout");

          this.redirect_text = this.$t("error.401.link_text");
          this.redirect_href =
            "/bwapp/login?redirect=" + this.$router.currentRoute.fullPath;
          return this.$t("error.401.description");
        case 403:
          return this.$t("error.403.description");
        case 404:
          return this.$t("error.404.description");
        case 498:
          return this.$t("error.498.description");
        case 500:
          return this.$t("error.500.description");
        case 503:
          return this.$t("error.503.description");
        default:
          return this.$t("error.impossible_action");
      }
    },
  },
};
</script>
