import axios from "../services/api";

let default_root = '/bwapi/';
// let root = default_root + 'xxx';
// let config_name = 'xxx';

export default {
    getActionsToDo(callback) {
        axios.get(default_root + "actions-to-do")
            .then((response) => {
                callback(response.data);
            });
    },
    getInitInfos(callback) {
        return axios.get(default_root + "init-infos")
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    setCurrentCurrency(code) {
        return axios.post(default_root + "currency/change", { code: code })
            .then((response) => {
                return response.data
            });
    },
    setCurrentLanguage(code) {
        return axios.post(default_root + "language/change", { code: code })
            .then((response) => {
                return response.data
            });
    },
    getHeaderInfos(callback) {
        axios.get(default_root + "header-infos")
            .then((response) => {
                callback(response.data);
            });
    },
    getMainMenu(callback) {
        axios.get(default_root + "main-menu")
            .then((response) => {
                callback(response.data);
            });
    },
    changePriceDisplay(price_display_inctax, callback) {
        return axios.post(default_root + "change-price-display", { price_display_inctax: price_display_inctax })
            .then((response) => {
                callback(response.data);
            });
    },
}