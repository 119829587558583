import api from '../../api/dashboard'

// initial state
const state = () => ({
    charts: null,
})

// getters
const getters = {
}

// actions
const actions = {
    getItems({ commit }) {
        api.getPage(infos => {
            commit('setTitle', infos.title, { root: true })
            commit('setIcon', infos.icon, { root: true })
            commit('setBreadcrumbs', infos.breadcrumbs, { root: true })
            commit('setCharts', infos.charts)
        })
    },
    
}

// mutations
const mutations = {
    setCharts(state, charts) {
        state.charts = charts
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}