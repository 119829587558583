<template>
  <b-dropdown
    variant="link"
    :toggle-class="{ 'text-decoration-none nav-link': true, 'text-danger': current_user.real_user }"
  >
    <template #button-content>
      {{ current_user.label }}
    </template>

    <b-dropdown-item
      v-if="current_user.real_user"
      :href="current_user.real_user.get_back_url"
      link-class="text-danger"
    >
      <font-awesome-icon
        :icon="['fal', 'sign-out']"
        class="mr-1"
        flip="horizontal"
      />
      {{ $t('global.action.get_back_as', { user: current_user.real_user.label }) }}
    </b-dropdown-item>

    <template v-if="icons">
      <template v-if="current_user.not_external">
        <b-dropdown-item :to="{ name: 'user_profile', params: { id: current_user.id } }">
          <font-awesome-icon
            :icon="['fal', 'user']"
            class="mr-1"
          />
          {{ $t('user.profile') }}
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'delegations' }">
          <font-awesome-icon
            :icon="['fal', icons.delegation]"
            class="mr-1"
          />
          {{ $t('delegation.model.plural') }}
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'search_savings' }">
          <font-awesome-icon
            :icon="['fal', icons.search_saving]"
            class="mr-1"
          />
          {{ $t('search_saving.model.plural') }}
        </b-dropdown-item>
      </template>

      <b-dropdown-item
        :href="current_user.logout_url"
        @click.prevent="logout"
      >
        <font-awesome-icon
          :icon="['fal', icons.action.logout]"
          class="mr-1"
        />
        {{ $t('global.action.logout') }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderUser",
  created() {
    if (this.current_user === null) {
      this.$store.dispatch("user/getCurrentUser");
    }
  },
  computed: {
    ...mapState({
      current_user: (state) => state.user.current_user,
      icons: (state) => state.layout.icons,
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
    },
  },
};
</script>
