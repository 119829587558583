<template>
  <b-dropdown
    variant="link"
    toggle-class="text-decoration-none nav-link"
    menu-class="w-100"
  >
    <template
      #button-content
      class="nav-link"
    >
      {{ current_currency.code }}
    </template>

    <template v-for="(item, key) in available_currencies">
      <b-dropdown-item-button
        :key="key"
        :title="item.label"
        v-b-tooltip.hover.left
        @click="changeCurrency(item.code)"
      >
        {{ item.code }}
      </b-dropdown-item-button>
    </template>
  </b-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CurrencySelector",
  created() {
    if (this.available_currencies === null) {
      this.$store.dispatch("layout/getInitInfos");
    }
  },
  methods: {
    changeCurrency(currency) {
      this.$store.dispatch("layout/setCurrentCurrency", currency);
    },
  },
  computed: {
    ...mapState({
      current_currency: (state) => state.layout.current_currency,
      available_currencies: (state) => state.layout.available_currencies,
    }),
  },
};
</script>
