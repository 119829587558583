import axios from 'axios';
import router from "@/router"

let headers = {
    common: {
        'X-Requested-With': 'XMLHttpRequest',
    },
};

// let token = document.head.querySelector('meta[name="csrf-token"]');
// if (token) {
//     headers.common['X-CSRF-TOKEN'] = token.content;
// } else {
//     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
// }

const apiClient = axios.create({
    withCredentials: true,
    headers: headers
});

apiClient.interceptors.response.use(null, error => {
    let to = null;
    switch (error.response.status) {
        case 410:
        case 503:
            window.location.replace('/');
            break;
    }
    if (to !== null) {
        router.push(to);
        return Promise.reject(error);
    }
    throw error;
});

export default apiClient;