<template>
  <footer class="app-footer">
    <a
      :href="website.link ? website.link.root : null"
      target="_blank"
    >
      Boomerang Web
    </a>
    <span class="ml-1">
      © {{ new Date().getFullYear() }} TDCLIC.
    </span>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MainFooter",
  computed: {
    ...mapState({
      website: (state) =>
        state.layout.header_infos ? state.layout.header_infos.website : {},
    }),
  },
};
</script>
