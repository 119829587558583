<template>
  <quill-editor
    v-model="d_value"
    :id="id"
    class="mr-0 overflow-y-auto"
    :options="quill_options"
    :state="state"
    :disabled="disabled"
    @change="change"
  />
</template>

<script>
// wysiwyg
import Quill from "quill";
let AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import { quillEditor } from "vue-quill-editor";

export default {
  name: "BwWysiwyg",
  components: {
    quillEditor,
  },
  props: {
    id: { type: String, required: true },
    value: { required: true, nullable: true },
    state: { type: Boolean, default: null },
    placeholder: {
      type: String,
      default: function () {
        return this.$t("wysiwyg.info.insert_text_here");
      },
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      d_value: this.value,
      quill_options: {
        placeholder: this.placeholder,
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            [{ color: [] }],
            [{ background: [] }],
            ["bold", "underline"],
            [{ list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link", "image"],
            ["clean"],
          ],
        },
      },
    };
  },
  methods: {
    change(value) {
      this.$emit("input", value.html);
    },
  },
  watch: {
    value: function (value) {
      this.d_value = value;
    },
  },
};
</script>
