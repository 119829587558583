<template>
  <div
    v-if="main_menu"
    class="sidebar"
  >
    <div
      class="menu-logo"
      v-if="main_menu.logo"
    >
      <img
        class="navbar-brand-full mx-auto my-2"
        :src="main_menu.logo"
      >
    </div>

    <!-- <perfect-scrollbar> -->
    <nav class="sidebar-nav">
      <ul class="nav">
        <li
          v-if="!main_menu.logo"
          class="nav-title"
        >
          MENU
        </li>

        <template v-if="main_menu.items">
          <MainMenuLeaf
            v-for="(item, key) in main_menu.items"
            :key="key"
            :item="item"
          />
        </template>
      </ul>
    </nav>
    <!-- </perfect-scrollbar> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenuLeaf from "./MainMenuLeaf.vue";

export default {
  name: "MainMenu",
  components: { MainMenuLeaf },
  created() {
    if (this.main_menu === null) {
      this.$store.dispatch("layout/getMainMenu");
    }
  },
  computed: {
    ...mapState({
      main_menu: (state) => state.layout.main_menu,
    }),
  },
};
</script>
