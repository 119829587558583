<template>
  <multiselect
    v-model="selected"
    track-by="id"
    :label="label"
    :options="d_options"
    :loading="loading"
    :internal-search="!api || internal_search"
    :options-limit="options_limit"
    :allow-empty="!required"
    :multiple="multiple"
    :close-on-select="close_on_select"
    @open="search_on_open ? (api ? find('') : null) : null"
    @search-change="api && !internal_search ? waitAndFind($event) : null"
    @select="change"
    @remove="remove"
    :deselect-label="d_deselect_label"
    :placeholder="placeholder_label"
    :select-label="$t('filter.select_label')"
    :selected-label="$t('filter.selected_label')"
    :class="{ 'is-invalid': state === false }"
    :id="id"
    :ref="id"
    v-bind="$attrs"
  >
    <template
      slot="singleLabel"
      slot-scope="{ option }"
    >
      <slot
        name="rendered_label"
        :option="option"
      >
        <span v-html="option.label" />
      </slot>
    </template>
    <template
      slot="option"
      slot-scope="{ option }"
    >
      <slot
        name="option"
        :option="option"
      >
        <slot
          name="rendered_label"
          :option="option"
        >
          <span v-html="option.label" />
        </slot>
      </slot>
    </template>
    <template
      slot="tag"
      slot-scope="{ option, remove }"
    >
      <span class="multiselect__tag">
        <slot
          name="tag"
          :option="option"
        >
          <slot
            name="rendered_label"
            :option="option"
          >
            <span v-html="option.label" />
          </slot>
        </slot>
        <i
          aria-hidden="true"
          tabindex="1"
          class="multiselect__tag-icon"
          @click="remove(option)"
        />
      </span>
    </template>
    <template slot="noOptions">{{ $t('filter.no_options') }}</template>
    <template slot="noResult">{{ $t('filter.no_result') }}</template>
    <template slot="beforeList">
      <slot name="before_list"></slot>
      <li
        v-if="remove_option"
        role="option"
        class="multiselect__element"
      >
        <span
          :class="{ multiselect__option: true, 'multiselect__option--selected multiselect__option--highlight': hovering_remove_option }"
          @mouseover="hovering_remove_option = true"
          @mouseout="hovering_remove_option = false"
          @click="manualRemove"
        >
          {{ remove_option_label }}
        </span>
      </li>
    </template>
    <template slot="afterList">
      <slot name="after_list"></slot>
      <li
        v-if="options && options.length > options_limit"
        class="multiselect__element"
      >
        <span class="multiselect__option">{{ $t('filter.filter_to_show_more') }}</span>
      </li>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

export default {
  name: "BwMultiselect",
  components: {
    Multiselect,
  },
  props: {
    id: { type: String, required: true },
    value: { required: true, nullable: true },
    label: { type: String, default: "label" },
    api: { nullable: true },
    api_method: { type: String, nullable: true, default: "search" },
    api_params: { type: Object, nullable: true },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    internal_search: { type: Boolean, default: false },
    search_on_open: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    return_array: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    state: { type: Boolean, default: null },
    deselect_label: { type: String, default: null },
    remove_option: { type: [Boolean, String], default: false },
    placeholder: { type: String },
    refresh_after_select: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: this.value,
      d_options: this.options,
      options_limit: 100, // default
      loading: false,
      close_on_select: !this.multiple,
      placeholder_label: this.placeholder
        ? this.placeholder
        : this.$t("filter.placeholder"),
      remove_option_label:
        typeof this.remove_option === "string"
          ? this.remove_option
          : "-- " + this.$t("filter.deselect_label") + " --",
      hovering_remove_option: false,
    };
  },
  methods: {
    waitAndFind(query) {
      this.loading = true;
      if (this.timeoutId != 0) {
        clearTimeout(this.timeoutId);
        this.timeoutId = 0;
      }
      this.timeoutId = setTimeout(() => {
        this.timeoutId = 0;
        this.find(query);
      }, 300);
    },
    find(query) {
      this.loading = true;

      if (this.internal_search) {
        // no search query, only launched on multiselect open
        if (this.api_params) {
          this.api[this.api_method](
            this.api_params,
            (response) => {
              this.d_options = response.options;
              this.options_limit = response.limit;
              this.loading = false;
            },
            (failure) => {
              this.toast_error(null, null, failure.status);
            }
          );
        } else {
          this.api[this.api_method](
            (response) => {
              this.d_options = response.options;
              this.options_limit = response.limit;
              this.loading = false;
            },
            (failure) => {
              this.toast_error(null, null, failure.status);
            }
          );
        }
      } else {
        // potential search query, launched on multiselect open AND on search change
        if (this.api_params) {
          this.api[this.api_method](
            this.api_params,
            query,
            (response) => {
              // if (
              //   this.$refs[this.id].search == response.initial_search ||
              //   response.initial_search == null
              // ) {
              this.d_options = response.options;
              this.options_limit = response.limit;
              this.loading = false;
              // }
            },
            (failure) => {
              this.toast_error(null, null, failure.status);
            }
          );
        } else {
          this.api[this.api_method](
            query,
            (response) => {
              console.log('BwMultiselect find response',this.id);
              if (
                this.$refs[this.id].search == response.initial_search ||
                response.initial_search == null
              ) {
                this.d_options = response.options;
                this.options_limit = response.limit;
                this.loading = false;
              }
            },
            (failure) => {
              this.toast_error(null, null, failure.status);
            }
          );
        }
      }
    },
    change(value) {
      if (this.refresh_after_select) {
        this.waitAndFind();
      }
      if (this.multiple) {
        this.$emit("input", this.selected);
        this.$emit("change", this.selected);
      } else {
        if (this.return_array) {
          this.$emit("input", [value]);
          this.$emit("change", [value]);
        } else {
          this.$emit("input", value);
          this.$emit("change", value);
        }
      }
    },
    remove() {
      if (this.multiple) {
        this.$emit("input", this.selected);
        this.$emit("change", this.selected);
      } else {
        this.$emit("input", null);
        this.$emit("change", null);
      }
    },
    manualRemove() {
      this.selected = null;
      this.remove();
      if (this.close_on_select) {
        this.$el.__vue__.deactivate();
      }
    },
  },
  watch: {
    value: function (value) {
      this.selected = value;
    },
    options: function (value) {
      this.d_options = value;
    },
  },
  computed: {
    ...mapState({
      settings: (state) => state.layout.settings,
      trans: (state) => state.layout.trans,
    }),
    d_deselect_label() {
      if (this.deselect_label) {
        return this.deselect_label;
      }

      return (this.multiple && this.required && this.selected.length == 1) ||
        (!this.multiple && this.required)
        ? this.$t("filter.cant_deselect_label")
        : this.$t("filter.deselect_label");
    },
  },
};
</script>
