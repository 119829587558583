<template>
  <b-dropdown
    size="lg"
    variant="link"
    toggle-class="text-decoration-none nav-link"
    no-caret
    @show="getQuestions"
  >
    <template
      #button-content
      class="nav-link"
    >
      <font-awesome-layers
        :title="$t('faq.model')"
        v-b-tooltip
      >
        <font-awesome-icon
          v-if="icons"
          :icon="['fal', loading ? icons.info.loading : icons.faq]"
          :spin="loading"
        />
      </font-awesome-layers>
    </template>

    <template v-if="questions">
      <template v-for="(question, key) in questions">
        <b-dropdown-item
          :key="key"
          :href="question.url + question.url_params"
          target="_blank"
          @click="question.url === null ? update(question) : null"
          :title="question.url === null ? 'Lien manquant' : null"
        >
          <font-awesome-icon
            v-if="question.url === null"
            :icon="['fas', icons.info.warning]"
            class="text-danger"
          />
          {{ question.label }}

          <b-link
            v-if="can.manage"
            @click="update(question)"
            class="float-right ml-2"
            :title="$t('global.action.update')"
            v-b-tooltip
          >
            <font-awesome-icon :icon="['fal', icons.action.edit]" />
          </b-link>
        </b-dropdown-item>
      </template>
    </template>

    <b-dropdown-item
      v-else-if="loading"
      disabled
    >
      <font-awesome-icon
        v-if="icons"
        :icon="['fal', icons.info.loading]"
        spin
      />
      {{ $t('global.info.loading') }}
    </b-dropdown-item>

    <b-dropdown-header
      class="text-center"
      style="min-width: 350px"
    >
      <a
        :href="website.link.faq"
        target="_blank"
      >
        <font-awesome-icon
          v-if="icons"
          :icon="['fal', icons.info.external_link]"
        />
        {{ $t('faq.all') }}
      </a>
    </b-dropdown-header>

    <b-dropdown-header class="text-center">
      <a
        :href="website.link.contact_us"
        target="_blank"
      >
        <font-awesome-icon
          v-if="icons"
          :icon="['fal', icons.action.contact_us]"
        />
        {{ $t('global.action.contact_us') }}
      </a>
    </b-dropdown-header>

    <b-dropdown-header
      v-if="can.manage"
      class="text-center"
    >
      <b-link @click="create">
        <font-awesome-icon :icon="['fal', icons.action.add]" />
        {{ $t('faq.add') }}
      </b-link>
    </b-dropdown-header>

    <b-modal
      v-if="icons"
      :id="modal_ref"
      :ref="modal_ref"
      hide-footer
      size="md"
    >
      <template #modal-title>
        <div :style="colors ? colors.classes : null">
          <font-awesome-icon :icon="['fad', form.id ? icons.action.edit : icons.action.add]" />
          {{ form.id ? $t('global.action.update') : $t('faq.add') }}
        </div>
      </template>

      <b-form
        :style="colors ? colors.classes : null"
        @submit.prevent="submit()"
      >
        <b-form-group
          label-for="faq_label"
          label-cols-xl="3"
        >
          <template slot="label">
            {{ $t("global.field.label") }}
          </template>
          <b-form-input
            id="faq_label"
            v-model="form.label"
            :state="state('label')"
          />
          <b-form-invalid-feedback v-if="errors.label">{{ errors.label[0] }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label-for="faq_url"
          label-cols-xl="3"
        >
          <template slot="label">
            {{ $t("global.field.url") }}
          </template>
          <b-form-textarea-autosize
            id="faq_url"
            v-model="form.url"
            :state="state('url')"
          />
          <b-form-invalid-feedback v-if="errors.url">{{ errors.url[0] }}</b-form-invalid-feedback>
        </b-form-group>

        <div class="float-right">
          <b-button
            v-if="form.id"
            variant="danger"
            class="ml-1"
            @click="destroy"
          >
            <font-awesome-icon :icon="['fal', icons.action.delete]" />
            {{ $t('global.action.delete') }}
          </b-button>

          <b-button
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <font-awesome-icon :icon="['fal', icons.action.save]" />
            {{ form.id ? $t('global.action.update') : $t('global.action.create') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </b-dropdown>
</template>

<script>
import api from "../../api/question";
import BFormTextareaAutosize from "../../components/global/TextareaAutosize.vue";
import { mapState } from "vuex";

export default {
  name: "FaqButton",
  components: {
    BFormTextareaAutosize,
  },
  data() {
    return {
      loading: false,
      can: {},
      questions: null,
      previous_route: null,
      // modal
      modal_ref: "faq-modal",
      form: {},
      errors: {},
    };
  },
  created() {},
  methods: {
    state(field) {
      if (this.errors[field]) {
        return false;
      }
    },
    getQuestions() {
      let route_name = this.$route.name;
      if (this.previous_route != route_name) {
        this.loading = true;
        this.questions = null;

        return api.getContextualQuestions(
          route_name,
          (response) => {
            this.questions = response.questions;
            this.can = response.can;
            this.previous_route = route_name;
            this.loading = false;
          },
          (failure) => {}
        );
      }
    },
    create() {
      this.form = { route_name: this.$route.name };
      this.errors = {};
      this.$bvModal.show(this.modal_ref);
    },
    update(question) {
      this.form = question;
      this.errors = {};
      this.$bvModal.show(this.modal_ref);
    },
    submit() {
      this.loading = true;

      api.saveItem(
        this.form.id,
        this.form,
        (response) => {
          this.questions = null;
          this.previous_route = null;
          this.loading = false;

          this.$bvModal.hide(this.modal_ref);
        },
        (failure) => {
          this.toast_error(null, null, failure.status);
          this.errors = failure.data.errors;
          this.loading = false;
        }
      );
    },
    destroy() {
      this.loading = true;

      api.delete(
        this.form.id,
        null,
        (response) => {
          this.questions = null;
          this.previous_route = null;
          this.loading = false;
          this.$bvModal.hide(this.modal_ref);
        },
        (failure) => {
          this.toast_error(null, null, failure.status);
          this.loading = false;
        }
      );
    },
  },
  computed: {
    ...mapState({
      colors: (state) => state.layout.colors,
      icons: (state) => state.layout.icons,
      website: (state) => state.layout.header_infos.website,
    }),
  },
};
</script>
