<template>
  <li :class="'nav-item' + (item.subtree ? ' nav-dropdown' : '')">
    <component
      :is="item.to ? 'router-link' : 'a'"
      :class="'nav-link' + (item.subtree ? ' nav-dropdown-toggle' : '')"
      :to="item.to ? item.to : null"
      :href="item.link ? item.link : null"
    >
      <font-awesome-layers>
        <font-awesome-icon
          v-if="item.icon"
          :icon="['fal', item.icon]"
        />
      </font-awesome-layers>
      {{ item.text }}
    </component>

    <ul
      v-if="item.subtree"
      class="nav-dropdown-items"
    >
      <MainMenuLeaf
        v-for="(item, key) in item.subtree"
        :key="key"
        :item="item"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: "MainMenuLeaf",
  props: ["item"],
};
</script>
