import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n';

// modules
import cart from './modules/cart'
import dashboard from './modules/dashboard'
import layout from './modules/layout'
import supplier from './modules/supplier'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    title: i18n.t('global.info.loading'),
    icon: null,
    breadcrumbs: null,
    pages_infos: null,
    error_infos: null,
    last_route: null,
  },
  mutations: {
    setIcon(state, icon) {
      state.icon = icon
    },
    setTitle(state, title) {
      state.title = title
    },
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs
    },
    setPagesInfos(state, pages_infos) {
      state.pages_infos = pages_infos
    },
    setErrorInfos(state, error_infos) {
      state.error_infos = error_infos
    },
    setLastRoute(state, last_route) {
      state.last_route = last_route
    },
  },
  actions: {
    setPagesInfos({ commit }, pages_infos) {
      commit('setPagesInfos', pages_infos, { root: true })
    },
  },
  modules: {
    layout,
    //
    dashboard,
    //
    cart,
    supplier,
    user,
  }
})
