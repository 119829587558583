import axios from "../services/api";

let default_root = '/bwapi/';
let root = default_root + 'messages';
// let config_name = 'message';

export default {
    getIndex(callback, failure_callback) {
        return axios.get(root + "/index" + window.location.search)
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    refreshList(query, callback) {
        return axios.get(root + "?" + query)
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    getContextualMenu(item_id, callback) {
        return axios.get(root + "/" + item_id + "/contextual-menu")
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    getPreviewedItem(item_id, callback, failure_callback) {
        return axios.get(root + "/" + item_id + "/preview")
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    dismiss(item_id, callback, failure_callback) {
        return axios.get(root + "/" + item_id + "/dismiss")
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    getItem(item_id, callback, failure_callback) {
        if (item_id) {
            axios.get(root + "/" + item_id)
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        } else {
            axios.get(root + "/create")
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        }
    },
    saveItem(item_id, form, callback, failure_callback) {
        if (item_id) {
            axios.put(root + "/" + item_id, form)
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        } else {
            axios.post(root, form)
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        }
    },
    getDeleteInfos(item_id, callback) {
        axios.get(root + "/" + item_id + "/delete")
            .then((response) => {
                callback(response.data);
            })
    },
    delete(item_id, form, callback, failure_callback) {
        axios.put(root + "/" + item_id + "/delete", form)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
}