<template>
  <div v-if="messages">
    <b-alert
      v-for="message in messages"
      :key="'message' + message.id"
      :variant="message.variant"
      show
      fade
      :class="{ 'd-md-down-none mb-1 message-alert': true, 'alert-show-more': message.can_show }"
      :dismissible="message.dismissible"
      :dismiss-label="$t('global.action.never_see_again')"
      @dismissed="messageDismissed(message)"
    >
      <template slot="dismiss">
        <div
          :title="$t('global.action.never_see_again')"
          v-b-tooltip.hover
        >
          <font-awesome-icon
            v-if="icons"
            :icon="['fal', icons.action.close]"
          />
        </div>
      </template>

      <div
        :class="{ 'cursor-pointer': message.can_show }"
        @click="showMessage(message)"
      >
        <font-awesome-icon
          v-if="message.icon"
          :icon="['fal', message.icon]"
        />

        {{ message.label }}

        <span
          v-if="message.can_show"
          class="show-more"
        >{{ $t('global.action.show_more') }}</span>
      </div>
    </b-alert>

    <message-preview-modal
      id="message-modal"
      :message="selected_message"
    />
  </div>
</template>

<script>
import api from "../../api/message";
import MessagePreviewModal from "../../components/message/PreviewModal";
import { mapState } from "vuex";

export default {
  name: "MessagePreviewListing",
  props: {
    messages: {
      default: null,
    },
  },
  components: {
    MessagePreviewModal,
  },
  data() {
    return {
      message_loading: false,
      selected_message: null,
      list_key: "message",
    };
  },
  computed: {
    ...mapState({
      colors: (state) => state.layout.colors,
      icons: (state) => state.layout.icons,
    }),
  },
  methods: {
    messageDismissed(message) {
      if (message.dismissible) {
        api.dismiss(
          message.id,
          () => {
            this.$store.dispatch("layout/getHeaderInfos");
            this.$root.$emit("refresh-list-" + this.list_key);
          },
          (failure) => {
            this.toast_error(null, null, failure.status);
          }
        );
      }
    },
    showMessage(message) {
      if (message.can_show) {
        this.message_loading = true;
        this.selected_message = message;

        this.$bvModal.show("message-modal");

        api.getPreviewedItem(
          message.id,
          (response) => {
            this.selected_message = response.message;

            this.message_loading = false;
          },
          (failure) => {
            this.toast_error(null, null, failure.status);
          }
        );
      }
    },
  },
};
</script>
