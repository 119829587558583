<template>
  <div
    :class="{ expandable: true, expanded: expanded }"
    @focus="expanded = true"
    @focusout="expanded = false"
    tabindex="0"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "BwExpandable",
  data() {
    return {
      expanded: false,
    };
  },
};
</script>

<style>
.expandable {
  position: absolute;
  z-index: 1;
  overflow: hidden;

  max-height: 40px;

  transition: max-height 0.3s, box-shadow 0.3s, background-color 1s;
}

.expandable:hover,
.expandable.expanded {
  max-height: 200px;
  background-color: #fff;
  box-shadow: 0 0 10px 10px #eee;

  transition: max-height 0.3s, box-shadow 0.3s;
}
</style>
