import api from '../../api/supplier/supplier'

// initial state
const state = () => ({
    supplier: null,
    header: null,
    charts: null,
    chart_additional_rating: null,
    chart_annual_rating: null,
    chart_certification: null,
    chart_performance: null,
    chart_security: null,
    charts_turnover: null,
})

// getters
const getters = {
}

// actions
const actions = {
    getHeader({ commit }, supplier) {
        return api.getHeader(supplier.id, header => {
            commit('setHeader', header)
        }, failure => {
            commit("setErrorInfos", failure, { root: true });
        })
    },
    getCharts({ commit }, supplier) {
        api.getCharts(supplier.id, infos => {
            commit('setTitle', infos.title, { root: true })
            commit('setIcon', infos.icon, { root: true })
            commit('setBreadcrumbs', infos.breadcrumbs, { root: true })
            commit('setSupplier', infos.supplier)
            commit('setCharts', infos.charts)
        })
    },
    getChartAdditionalRating({ commit }, supplier) {
        return api.getChartAdditionalRating(supplier.id, chart => {
            commit('setChartAdditionalRating', chart)
        })
    },
    saveAdditionalRating(context, { supplier, form }) {
        return api.saveAdditionalRating(supplier.id, form);
    },
    getChartAnnualRating({ commit }, supplier) {
        api.getChartAnnualRating(supplier.id, chart => {
            commit('setChartAnnualRating', chart)
        })
    },
    getChartCertification({ commit }, supplier) {
        return api.getChartCertification(supplier.id, chart => {
            commit('setChartCertification', chart)
        })
    },
    getChartPerformance({ commit }, supplier) {
        api.getChartPerformance(supplier.id, chart => {
            commit('setChartPerformance', chart)
        })
    },
    getChartSecurity({ commit }, supplier) {
        return api.getChartSecurity(supplier.id, chart => {
            commit('setChartSecurity', chart)
        })
    },
    saveSecurity(context, { supplier, form }) {
        return api.saveSecurity(supplier.id, form);
    },
    getChartsTurnover({ commit }, supplier) {
        return api.getChartsTurnover(supplier.id, charts => {
            commit('setChartsTurnover', charts)
        })
    },
    saveTurnover(context, { supplier, form }) {
        return api.saveTurnover(supplier.id, form);
    },
}

// mutations
const mutations = {
    setHeader(state, header) {
        state.header = header
    },
    setCharts(state, charts) {
        state.charts = charts
    },
    setChartAdditionalRating(state, chart_additional_rating) {
        state.chart_additional_rating = chart_additional_rating
    },
    setChartAnnualRating(state, chart_annual_rating) {
        state.chart_annual_rating = chart_annual_rating
    },
    setChartCertification(state, chart_certification) {
        state.chart_certification = chart_certification
    },
    setChartPerformance(state, chart_performance) {
        state.chart_performance = chart_performance
    },
    setChartSecurity(state, chart_security) {
        state.chart_security = chart_security
    },
    setChartsTurnover(state, charts_turnover) {
        state.charts_turnover = charts_turnover
    },
    setSupplier(state, supplier) {
        state.supplier = supplier
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}