import api from '../../api/catalog/cart'

// initial state
const state = () => ({
    regular: null,
    stock: {},
})

// getters
const getters = {
    getCart: (state) => (type) => {
        if (type.includes("warehouse_article_")) {
            return state.stock[type]
        } else {
            return state[type]
        }
    },
}

// actions
const actions = {
    get({ commit }, type) {
        api.get(type, cart => {
            commit('setCart', { type: type, cart: cart })
        })
    },
    add({ commit }, data) {
        return api.add(data.type, data.item, (cart) => {
            commit('setCart', { type: data.type, cart: cart })
        });
    },
    changeQuantity({ commit }, data) {
        api.changeQuantity(data.type, data.item, data.quantity, (cart) => {
            commit('setCart', { type: data.type, cart: cart })
        });
    },
    remove({ commit }, data) {
        api.remove(data.type, data.item, (cart) => {
            commit('setCart', { type: data.type, cart: cart })
        });
    },
    empty({ commit }, type) {
        api.empty(type, (cart) => {
            commit('setCart', { type: type, cart: cart })
        });
    },
    convert({ commit }, data) {
        return api.convert(data.type, data.form, (response) => {
            commit('setCart', { type: data.type, cart: response.cart })
        });
    },
}

// mutations
const mutations = {
    setCart(state, obj) {
        if (obj.type.includes("warehouse_article_")) {
            // ces lignes équivalent à state.stock[obj.type] = obj.cart, avec la réactivité en plus
            let new_object = {}
            new_object[obj.type] = obj.cart
            state.stock = { ...state.stock, ...new_object }
        } else {
            state[obj.type] = obj.cart
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}