import Vue from 'vue'
import VueInternationalization from 'vue-i18n';
import Messages from './assets/lang/vue-i18n-locales.generated';

Vue.use(VueInternationalization);

let lang = localStorage.getItem('lang');
if (lang === null) {
    lang = document.documentElement.lang.substr(0, 2);
}

const i18n = new VueInternationalization({
    locale: lang,
    messages: Messages
});

export default i18n;