<template>
  <span
    v-b-tooltip.hover
    :title="$t('global.info.field_translatable')"
    class="mx-1"
  >
    <font-awesome-icon
      :icon="['fal', icons.info.translation]"
      size="sm"
      class="text-black-50"
    />
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IconTranslatable",
  computed: {
    ...mapState({
      icons: (state) => state.layout.icons,
    }),
  },
};
</script>
