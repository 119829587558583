<template>
  <span
    v-b-tooltip.hover
    :title="$t('global.info.not_updatable_after_creation')"
    class="mx-1"
  >
    <font-awesome-icon
      :icon="['fal', icons.info.not_updatable_after_creation]"
      size="sm"
      class="text-black-50"
    />
  </span>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IconNotUpdatableAfterCreation",
  computed: {
    ...mapState({
      icons: (state) => state.layout.icons,
    }),
  },
};
</script>
