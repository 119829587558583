import axios from "../services/api";

let default_root = '/bwapi/';
let root = default_root + 'questions';
// let config_name = 'xxx';

export default {
    getIndex(callback, failure_callback) {
        return axios.get(root + "/index" + window.location.search)
            .then((response) => {
                callback(response.data);
                return response.data
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    refreshList(query, callback) {
        return axios.get(root + "?" + query)
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    getContextualMenu(item_id, callback) {
        return axios.get(root + "/" + item_id + "/contextual-menu")
            .then((response) => {
                callback(response.data);
                return response.data
            });
    },
    getContextualQuestions(route_name, callback, failure_callback) {
        axios.get(root + "/contextual/" + route_name)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
    saveItem(item_id, form, callback, failure_callback) {
        if (item_id) {
            axios.put(root + "/" + item_id, form)
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        } else {
            axios.post(root, form)
                .then((response) => {
                    callback(response.data);
                })
                .catch((response) => {
                    failure_callback(response.response);
                });
        }
    },
    delete(item_id, form, callback, failure_callback) {
        axios.delete(root + "/" + item_id)
            .then((response) => {
                callback(response.data);
            })
            .catch((response) => {
                failure_callback(response.response);
            });
    },
}